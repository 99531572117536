import "./style.css";

import gsap from "gsap";
(function () {
  const body = document.querySelector("body");

  document
    .getElementById("footerButton")
    .addEventListener("click", function () {
      if (document.getElementById("footerwrapper").dataset.active === "false") {
        gsap.to("#footerwrapper", {
          height: "90vh",
          duration: 1,
          ease: "circ",
        });
        document.getElementById("footerwrapper").dataset.active = true;
        document.getElementById("footermenu").classList.add("active");
      } else {
        gsap.to("#footerwrapper", {
          height: "5vh",
          duration: 1,
          ease: "circ",
        });
        document.getElementById("footerwrapper").dataset.active = false;
        document.getElementById("footermenu").classList.remove("active");
      }
    });

  document.getElementById("shop").addEventListener("click", function () {
    console.log("shop");
    try {
      videoCantiere.classList.remove("visible");
      videoMoto.classList.remove("visible");
      videoDarsena.classList.remove("visible");
      videoMoto.pause();
      videoCantiere.pause();
      videoDarsena.pause();
      videoShop.classList.add("visible");
      videoShop.play();
      setTimeout(function () {
        gsap.to("#body", { opacity: 0, duration: 1, ease: "ease-out" });
        setTimeout(function () {
          window.location.replace(
            "https://www.marina4.com/categoria-prodotto/shop/"
          );
        }, 1000);
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  });

  document.getElementById("cantiere").addEventListener("click", function () {
    console.log("cantiere");
    try {
      videoMoto.classList.remove("visible");
      videoShop.classList.remove("visible");
      videoDarsena.classList.remove("visible");
      videoMoto.pause();
      videoShop.pause();
      videoDarsena.pause();
      videoCantiere.classList.add("visible");
      videoCantiere.play();
      setTimeout(function () {
        gsap.to("#body", { opacity: 0, duration: 1, ease: "ease-out" });
        setTimeout(function () {
          window.location.replace("https://www.marina4.com/cantiere/");
        }, 1000);
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  });

  document.getElementById("darsena").addEventListener("click", function () {
    console.log("darsena");
    try {
      videoCantiere.classList.remove("visible");
      videoShop.classList.remove("visible");
      videoMoto.classList.remove("visible");
      videoMoto.pause();
      videoShop.pause();
      videoCantiere.pause();
      videoDarsena.classList.add("visible");
      videoDarsena.play();
      setTimeout(function () {
        gsap.to("#body", { opacity: 0, duration: 1, ease: "ease-out" });
        setTimeout(function () {
          window.location.replace("https://www.marina4.com/darsena/");
        }, 1000);
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  });

  document.getElementById("moto").addEventListener("click", function () {
    console.log("moto");
    try {
      videoCantiere.classList.remove("visible");
      videoShop.classList.remove("visible");
      videoDarsena.classList.remove("visible");
      videoCantiere.pause();
      videoShop.pause();
      videoDarsena.pause();
      videoMoto.classList.add("visible");
      videoMoto.play();
      setTimeout(function () {
        gsap.to("#body", { opacity: 0, duration: 1, ease: "ease-out" });
        setTimeout(function () {
          window.location.replace(
            "https://www.marina4.com/categoria-prodotto/yamaha/"
          );
        }, 1000);
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  });

  document.getElementById("maree").addEventListener("click", function () {
    setTimeout(function () {
      gsap.to("#body", { opacity: 0, duration: 1, ease: "ease-out" });
      setTimeout(function () {
        window.location.replace("https://www.marina4.com/marea/");
      }, 1000);
    }, 1000);
  });

  document.getElementById("sceneWrapper").click(function (e) {
    console.log(e.target);
  });
})();
